import React from 'react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { Helmet } from 'react-helmet-async';
import { nafColor } from '@nafcore/theme';
import S from '../styles/articles/StyledArticle';

import BreadCrumb from '../components/breadcrumb/BreadCrumb';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webmanifest from '../../assets/manifest.webmanifest?url';

const ArticleTestPage = () => (
  <S.InternalWrapper>
    <Helmet>
      <title>NAF testartikkel</title>
      <base href="/" />
      <html lang="no" dir="ltr" />
      <meta name="language" content="nb_NO" />
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="og:type" content="website" />
      <meta name="theme-color" content={nafColor.signature.white} />
      <meta name="description" content="Dette er en testartikkel" key="description" />
      <meta property="og:title" content="Testartikkel" key="title" />
      <meta property="og:url" content="https://www.naf.no/testartikkel" key="og:url" />
      <meta property="og:description" content="Dette er en testartikkel" key="og:description" />
      <link rel="manifest" href={`/static/${webmanifest}`} crossOrigin="use-credentials" />
      <link href="https://use.typekit.net/" rel="preconnect" />
      <script type="text/javascript">{`
          var _elqQ = _elqQ || [];
          _elqQ.push(['elqSetSiteId', '128040577']);
          _elqQ.push(['elqUseFirstPartyCookie', 'marketing.naf.no']);
          _elqQ.push(['elqTrackPageView']);

          (function() {
              function async_load() {
                  var s = document.createElement('script'); s.type = 'text/javascript';
                  s.async = true;
                  s.src = '//img.en25.com/i/elqCfg.min.js';
                  var x = document.getElementsByTagName('script')[0];
                  x.parentNode.insertBefore(s, x);
              }
              if(window.addEventListener) window.addEventListener('DOMContentLoaded', async_load, false);
              else if (window.attachEvent) window.attachEvent('onload', async_load);
          })();
      `}</script>
    </Helmet>
    <S.Content>
      <S.Wrapper>
        <Grid>
          <BreadCrumb />
          <GridCol s="12" m="12" l="8" xl="8">
            <Text tag="h1" variant={TextVariant.Header1}>
              Steinsprutskader har mer en doblet seg på 20 år
            </Text>
            <S.Ingress tag="p" variant={TextVariant.Ingress}>
              Steinsprutskader i trafikken er et like sikkert vårtegn som at løvetannen blomstrer. Tall fra
              forsikringsselskapene viser en kraftig økning i steinsprutskader.
            </S.Ingress>
            <S.HRLine />
          </GridCol>
          <GridCol s="12" m="12" l="8" xl="8">
            <S.Body>
              <p>
                Hold ekstra avstand til andre biler, særlig ved påkjøringsfiler hvor farten økes raskt, rådgir Nils
                Sødal, senior kommunikasjonsrådgiver i NAF.
              </p>

              <p>
                Tallenes tale er klar. Stadig flere opplever å få irriterende steinsprutskader på frontruta. Fra 122.178
                innmeldte glasskader i 2000 økte antallet til 260.981 innmeldte tilfeller i 2020. Dette viser tall fra
                Finans Norge.
              </p>

              <p>
                Når snøen smelter kommer grus og sand. Derfor er våren den perioden da flest frontruter får
                steinsprutskader.
              </p>

              <p>
                - Vinterdekk med myk gummi fanger opp mye småstein fra grusen som ligger i veibanen. Når hastigheten
                øker skytes steinene ut av dekkene igjen og fyker bakover på bilene som kommer etter deg i trafikken,
                sier Nils Sødal.
              </p>
            </S.Body>
          </GridCol>
        </Grid>
      </S.Wrapper>
    </S.Content>
  </S.InternalWrapper>
);

export default ArticleTestPage;
